<template>
  <div>
    <b-modal
      id="modal-details"
      v-model="showLocal"
      centered
      size="lg"
      title="Detalhes"
      hide-footer
      @hidden="hidden"
    >
      {{ bill }}
      <b-row>

        <!-- Field: Origem -->
        <b-col
          cols="12"
          md="6"
          class="mt-25 mb-1"
        >
          <!-- <span>Origem:</span> -->
          <span class="font-medium-2">Origem:</span>
          <span class="font-medium-4 font-weight-bolder"> Orçamento</span>
        </b-col>

        <!-- Field: Paciente -->
        <b-col
          cols="12"
          md="6"
          class="mt-25 mb-1"
        >
          <!-- <span>Data de Efetivação:</span> -->
          <span class="font-medium-2">Data de Efetivação:</span>
          <span class="font-medium-4 font-weight-bolder"> 05/11/2021</span>
        </b-col>

        <b-col
          cols="12"
          md="12"
        >
          <b-table
            striped
            hover
            :items="items"
            :fields="fields"
            responsive
            show-empty
            empty-text="Nenhum resultado encontrado"
          />
        </b-col>
      </b-row>
      <hr>
      <b-row>
        <b-col
          cols="8"
          md="9"
        >
          &nbsp;
        </b-col>

        <b-col
          cols="12"
          md="3"
        >
          <b-button
            variant="outline-secondary"
            block
            @click="hidden"
          >
            Fechar
          </b-button>
        </b-col>
      </b-row>

    </b-modal>
  </div>
</template>

<script>
import {
  BModal, BRow, BCol, BTable, BButton,
} from 'bootstrap-vue'

import {
  ref, toRefs, watch,
} from '@vue/composition-api'

export default {
  components: {
    BModal,
    BRow,
    BCol,
    BTable,
    BButton,
  },

  props: {
    bill: {
      type: Object,
      default: () => ({}),
    },
    show: {
      type: Boolean,
      default: false,
    },
  },

  setup(props, { emit }) {
    const showLocal = ref(false)
    const { show } = toRefs(props)

    const fields = ref([
      {
        label: 'vencimento',
        key: 'dueDate',
      },
      {
        label: 'pago em',
        key: 'paymentDate',
      },
      {
        label: 'descrição',
        key: 'details',
      },
      {
        label: 'Valor',
        key: 'value',
      },
    ])

    const items = []

    watch(show, newValue => {
      showLocal.value = newValue
    })

    const hidden = () => {
      emit('hidden')
    }

    return {
      showLocal,
      fields,
      items,

      hidden,
    }
  },
}
</script>
