<template>
  <div>
    <b-modal
      id="modal-pay"
      v-model="showLocal"
      centered
      size="xl"
      title="Pagamento"
      hide-footer
      @hidden="hidden"
    >
      <b-row>

        <!-- Table: Selected Items -->
        <b-col
          cols="12"
          md="8"
        >
          <b-table
            striped
            hover
            :items="bills"
            :fields="tableColumns"
          >

            <!-- Colunm: Vencimento -->
            <template #cell(dueDate)="data">
              {{ data.item.dueDate | dateTime('date') }}
            </template>

            <!-- Colunm: Value -->
            <template #cell(value)="data">
              {{ data.item.value && data.item.value.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' }) }}
            </template>

            <!-- Column: Actions -->
            <template #cell(actions)="data">
              <b-dropdown
                variant="link"
                no-caret
              >
                <template #button-content>
                  <feather-icon
                    icon="MoreVerticalIcon"
                    size="16"
                    class="align-middle text-body"
                  />
                </template>
                <b-dropdown-item
                  @click="remove(data)"
                >
                  <feather-icon icon="XCircleIcon" />
                  <span class="align-middle ml-50">Remover</span>
                </b-dropdown-item>
              </b-dropdown>
            </template>
          </b-table>
        </b-col>

        <b-col
          cols="12"
          md="4"
        >
          <b-form>

            <b-row>

              <!-- Field: Total a Pagar -->
              <b-col
                cols="12"
                md="12"
                class="align-self-right text-right"
              >
                <sup class="font-medium-1">R$</sup>
                <span class="font-large-1 font-weight-bolder">{{ totalValue.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' }).replace('R$', '') }}</span>
              </b-col>

            </b-row>

            <b-row>

              <h3
                v-if="!accountsLocal.length"
              >
                É necessária a configuração de contas!
              </h3>

              <!-- v-for Fileld: Contas -->
              <b-col
                v-for="account in accountsLocal"
                :key="account.id"
                cols="12"
                md="12"
              >
                <b-form-group
                  :label="account.account"
                >
                  <!-- Field: v-else-if: Banco -->
                  <b-input-group v-if="account.type === 'BANK'">
                    <b-input-group-prepend is-text>
                      <span>R$</span>
                    </b-input-group-prepend>
                    <currency-input
                      v-model="account.value"
                    />
                    <b-input-group-append>
                      <b-button
                        variant="primary"
                        @click="openModalBankInfo(account)"
                      >
                        Detalhes
                      </b-button>
                    </b-input-group-append>
                  </b-input-group>

                  <b-input-group v-else>
                    <b-input-group-prepend is-text>
                      <span>R$</span>
                    </b-input-group-prepend>
                    <currency-input
                      v-model="account.value"
                    />
                  </b-input-group>
                </b-form-group>
              </b-col>
            </b-row>
          </b-form>
        </b-col>
        <b-col
          cols="12"
          md="12"
        >
          <hr>
        </b-col>
      </b-row>
      <b-row>
        <b-col
          cols="8"
          md="6"
        >
          &nbsp;
        </b-col>

        <b-col
          cols="12"
          md="3"
        >
          <b-button
            variant="outline-secondary"
            block
            @click="hidden"
          >
            Cancelar
          </b-button>
        </b-col>
        <b-col
          cols="12"
          md="3"
        >
          <b-button
            variant="primary"
            block
            :disabled="!accountsLocal.length || !bills.length"
            @click="confirmTransaction"
          >
            Confirmar
          </b-button>
        </b-col>
      </b-row>
    </b-modal>

    <!-- Modal: Bank Info -->
    <modal-bank-info
      :account="tmpAccount"
      :show="bankInfoShowModal"
      @hidden="bankInfoShowModal = false"
    />

    <!-- Modal: Confirm -->
    <modal-confirm
      :confirm="confirm"
      @confirmedEmit="confirmEmit"
      @canceledEmit="cancelEmit"
    />

  </div>
</template>

<script>
import {
  BRow, BCol, BFormGroup, BForm, BInputGroup,
  BTable, BButton, BInputGroupAppend, BDropdown, BDropdownItem, BInputGroupPrepend,
} from 'bootstrap-vue'
import {
  ref, toRefs, watch,
} from '@vue/composition-api'

import { currency, dateTime } from '@core/utils/filter'
import CurrencyInput from '@/views/components/currency/CurrencyInput.vue'
import modalBankInfo from './modalBankInfo.vue'
import modalConfirm from './modalConfirm.vue'

export default {
  components: {
    BRow,
    BCol,
    BFormGroup,
    BForm,
    BInputGroup,
    BTable,
    BButton,
    BInputGroupAppend,
    BDropdown,
    BDropdownItem,
    BInputGroupPrepend,
    CurrencyInput,

    modalBankInfo,
    modalConfirm,
  },
  props: {
    bills: {
      type: Array,
      default: () => [],
    },
    totalValue: {
      type: Number,
      default: 0,
    },
    show: {
      type: Boolean,
      default: false,
    },
    accounts: {
      type: Array,
      default: () => [],
    },
  },

  setup(props, { root, emit }) {
    const showLocal = ref(false)
    const accountsLocal = ref([])

    const {
      show, accounts, bills, totalValue,
    } = toRefs(props)

    const confirm = ref(false)

    watch(show, newValue => {
      showLocal.value = newValue
      accountsLocal.value = JSON.parse(JSON.stringify(accounts.value))
      accountsLocal.value = accountsLocal.value.map(e => {
        Object.assign(e, { value: 0 })
        return e
      })
    })

    const tmpAccount = ref({})
    const bankInfoShowModal = ref(false)

    const hidden = () => {
      accountsLocal.value = []
      emit('hidden')
    }

    const openModalBankInfo = account => {
      bankInfoShowModal.value = true
      tmpAccount.value = account
    }

    const confirmEmit = () => {
      confirm.value = false
      const payload = {
        accounts: accountsLocal.value,
        bills: bills.value,
        totalValue: accountsLocal.value.reduce((acc, curr) => acc + curr.value, 0),
      }

      emit('save', payload)
      hidden()
    }

    const confirmTransaction = () => {
      if (totalValue.value.toFixed(2) !== accountsLocal.value.reduce((acc, curr) => acc + curr.value, 0).toFixed(2)) {
        root.$swal({
          title: 'Atenção!',
          text: 'O valor pago não pode ser diferente do valor total.',
          icon: 'warning',
          customClass: {
            confirmButton: 'btn btn-primary',
          },
          buttonsStyling: false,
        })
        return
      }
      confirm.value = true
    }

    return {
      showLocal,
      tmpAccount,
      bankInfoShowModal,
      accountsLocal,
      confirm,

      hidden,
      openModalBankInfo,
      confirmEmit,
      confirmTransaction,
    }
  },

  data() {
    return {
      tableColumns: [
        {
          key: 'patient',
          label: 'Ficha',
          formatter: value => (value ? value.code : '#'),
          tdClass: 'codeTdClass',
          sortable: true,
        },
        {
          key: 'transactable.trading_name',
          label: 'fornecedor',
          tdClass: 'providerTdClass',
          sortable: true,
        },
        {
          key: 'expiration_date',
          label: 'vencimento',
          formatter: value => dateTime(value),
          tdClass: 'expirationDateTdClass',
          sortable: true,
        },
        {
          key: 'value_installment',
          label: 'valor',
          formatter: currency,
          tdClass: 'valueInstallmentTdClass',
          sortable: true,
        },
        {
          key: 'actions',
          label: 'ações',
          tdClass: 'actionsTdClass',
          sortable: false,
        },
      ],
    }
  },

  methods: {
    cancelEmit() {
      this.confirm = false
    },

    remove(data) {
      this.bills.splice(data.index, 1)
    },
  },
}
</script>

<style lang="scss">
  @import '@core/scss/vue/libs/vue-select.scss';
</style>
