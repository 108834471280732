import { render, staticRenderFns } from "./modalDetails.vue?vue&type=template&id=6c5ee707"
import script from "./modalDetails.vue?vue&type=script&lang=js"
export * from "./modalDetails.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports