<template>
  <validation-observer
    #default="{invalid}"
    ref="refFormObserver"
  >
    <b-modal
      id="modal-new-bill"
      v-model="showLocal"
      centered
      size="lg"
      title="Lançamento"
      hide-footer
      @hidden="hidden"
    >
      <b-form>
        <b-row>
          <b-col
            cols="12"
            md="6"
          >
            <validation-provider
              #default="{ errors }"
              :rules="'required'"
              name="Fornecedor"
              immediate
            >
              <b-form-group
                label="Fornecedor"
                label-for="provider"
                :state="errors.length > 0 ? false : null"
              >
                <v-select
                  v-model="bill.providerId"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  placeholder="Buscar fornecedor..."
                  :clearable="true"
                  :options="providerOptions"
                  class="w-100"
                  :reduce="option => option.value"
                  :filterable="false"
                  input-id="provider"
                  label="label"
                  @open="onOpen"
                  @search="(val, loading) => debouncedSearchProvider(val, loading)"
                >
                  <template #no-options="{}">
                    Nenhum resultado encontrado.
                  </template>
                </v-select>
                <b-form-invalid-feedback :state="errors.length > 0 ? false : null">
                  {{ errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>
          </b-col>

          <b-col
            cols="12"
            md="6"
          >
            <b-form-group
              label="Vincular Paciente"
              label-for="patient"
            >
              <v-select
                v-model="bill.patient_id"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                placeholder="Buscar paciente..."
                :clearable="true"
                :options="patientOptions"
                class="w-100"
                :reduce="option => option.value"
                :filterable="false"
                input-id="provider"
                label="label"
                @open="onOpen"
                @search="(val, loading) => debouncedSearchPatient(val, loading)"
              >
                <template #no-options="val">
                  {{ val.search.length ? 'Nenhum resultado encontrado.' : 'Inicie com o código, nome ou CPF do paciente...' }}
                </template>
              </v-select>
            </b-form-group>
          </b-col>

          <!-- Field: Tipo -->
          <b-col
            cols="12"
            md="4"
          >
            <b-form-group
              label="Tipo"
              label-for="doc-type"
            >
              <v-select
                v-model="bill.doc_type"
                :searchable="false"
                :clearable="false"
                input-id="doc-type"
                label="label"
                :reduce="option => option.value"
                :options="billDocTypeOptions"
              />
            </b-form-group>
          </b-col>

          <!-- Field: Número -->
          <b-col
            cols="12"
            md="4"
          >
            <b-form-group
              label="Número"
              label-for="number"
            >
              <b-input-group>
                <b-form-input
                  id="number"
                  v-model="bill.number"
                  type="text"
                />
              </b-input-group>
            </b-form-group>
          </b-col>

          <!-- Field: Valor -->
          <b-col
            cols="12"
            md="4"
          >
            <b-form-group
              label="Valor"
              label-for="bill-value"
            >
              <b-input-group>
                <b-input-group-prepend is-text>
                  <span>R$</span>
                </b-input-group-prepend>
                <currency-input
                  v-model="bill.value_installment"
                />
              </b-input-group>
            </b-form-group>
          </b-col>

          <!-- Field: Vencimento -->
          <b-col
            cols="12"
            md="4"
          >
            <validation-provider
              #default="{ errors }"
              :rules="'required'"
              name="Vencimento"
              immediate
            >
              <b-form-group
                label="Vencimento"
                label-for="bill-due-date"
              >
                <b-input-group>
                  <b-form-input
                    id="bill-due-date"
                    v-model="bill.expiration_date"
                    type="date"
                    :state="errors.length > 0 ? false : null"
                  />
                  <b-form-invalid-feedback>{{ errors[0] }}</b-form-invalid-feedback>
                </b-input-group>
              </b-form-group>
            </validation-provider>
          </b-col>

          <!-- Field: Data de Recebimento do Doc -->
          <b-col
            cols="12"
            md="4"
          >
            <validation-provider
              #default="{ errors }"
              :rules="'required'"
              name="Recebimento"
              immediate
            >
              <b-form-group
                label="Recebimento do Documento"
                label-for="bill-receiving-data"
              >
                <b-input-group>
                  <b-form-input
                    id="bill-receiving-data"
                    v-model="bill.receiving_date"
                    type="date"
                    :state="errors.length > 0 ? false : null"
                  />
                  <b-form-invalid-feedback>{{ errors[0] }}</b-form-invalid-feedback>
                </b-input-group>
              </b-form-group>
            </validation-provider>
          </b-col>

          <!-- Field: É Recorrente? -->
          <b-col
            cols="12"
            md="4"
          >
            <b-form-group
              label="É recorrente?"
              label-for="frequency-boolean"
            >
              <v-select
                v-model="bill.frequentPayment"
                :reduce="option => option.value"
                :searchable="false"
                :clearable="false"
                input-id="frequency-boolean"
                label="label"
                :options="frequentPaymentOptions"
                :disabled="!!bill.id"
              />
            </b-form-group>
          </b-col>

          <!-- Field: Multa (precent) -->
          <b-col
            v-if="fineMode === 'percent'"
            cols="12"
            :md="bill.frequentPayment === true ? 3 : 6"
          >
            <validation-provider
              #default="{ errors }"
              name="Multa (%)"
              rules="required"
              immediate
            >
              <b-form-group
                label="Multa (%)"
                label-for="percent-fee"
              >
                <b-input-group>
                  <b-form-input
                    id="percent-fee"
                    v-model="bill.percent_fee"
                    placeholder="Valor percentual"
                    type="number"
                    :state="errors.length > 0 ? false : null"
                  />
                  <b-input-group-append>
                    <b-button
                      variant="primary"
                      @click="changeFineMode"
                    >
                      %
                    </b-button>
                  </b-input-group-append>
                  <b-form-invalid-feedback>{{ errors[0] }}</b-form-invalid-feedback>
                </b-input-group>
              </b-form-group>
            </validation-provider>
          </b-col>

          <!-- Field: Multa (modular) -->
          <b-col
            v-if="fineMode === 'modular'"
            cols="12"
            :md="bill.frequentPayment === true ? 3 : 6"
          >
            <validation-provider
              #default="{ errors }"
              name="Multa (R$)"
              rules="required"
              immediate
            >
              <b-form-group
                label="Multa (R$)"
                label-for="modular-fee-value"
              >
                <b-input-group>
                  <currency-input
                    id="modular-fee-value"
                    v-model="bill.modular_fee"
                    :class="{'is-invalid': errors.length > 0}"
                  />
                  <b-input-group-append>
                    <b-button
                      variant="primary"
                      @click="changeFineMode"
                    >
                      R$
                    </b-button>
                  </b-input-group-append>
                  <b-form-invalid-feedback>{{ errors[0] }}</b-form-invalid-feedback>
                </b-input-group>
              </b-form-group>
            </validation-provider>
          </b-col>

          <!-- Field: Juros (precent) -->
          <b-col
            v-if="feesMode === 'percent'"
            cols="12"
            :md="bill.frequentPayment === true ? 3 : 6"
          >
            <validation-provider
              #default="{ errors }"
              name="Juros (%)"
              rules="required"
              immediate
            >
              <b-form-group
                label="Juros (%)"
                label-for="percent-interest"
              >
                <b-input-group>
                  <b-form-input
                    id="percent-interest"
                    v-model="bill.percent_interest"
                    placeholder="Valor percentual"
                    type="number"
                    :state="errors.length > 0 ? false : null"
                  />
                  <b-input-group-append>
                    <b-button
                      variant="primary"
                      @click="changeFeesMode"
                    >
                      %
                    </b-button>
                  </b-input-group-append>
                  <b-form-invalid-feedback>{{ errors[0] }}</b-form-invalid-feedback>
                </b-input-group>
              </b-form-group>
            </validation-provider>
          </b-col>

          <!-- Field: Juros (modular) -->
          <b-col
            v-if="feesMode === 'modular'"
            cols="12"
            :md="bill.frequentPayment === true ? 3 : 6"
          >
            <validation-provider
              #default="{ errors }"
              name="Juros (R$)"
              rules="required"
              immediate
            >
              <b-form-group
                label="Juros (R$)"
                label-for="modular-interest-value"
              >
                <b-input-group>
                  <currency-input
                    id="modular-interest-value"
                    v-model="bill.modular_interest"
                    :class="{'is-invalid': errors.length > 0}"
                  />

                  <b-input-group-append>
                    <b-button
                      variant="primary"
                      @click="changeFeesMode"
                    >
                      R$
                    </b-button>
                  </b-input-group-append>
                  <b-form-invalid-feedback>{{ errors[0] }}</b-form-invalid-feedback>
                </b-input-group>
              </b-form-group>
            </validation-provider>
          </b-col>

          <!-- Quando: Não Recorrente -->
          <template v-if="bill.frequentPayment === false">
            <!-- Field: Descrição -->
            <b-col
              cols="12"
              md="12"
            >
              <b-form-group
                label="Descrição"
                label-for="bill-description"
              >
                <b-input-group>
                  <b-form-input
                    id="bill-description"
                    v-model="bill.description"
                    type="text"
                  />
                </b-input-group>
              </b-form-group>
            </b-col>
          </template>

          <!-- Quando :Recorrente -->
          <template v-if="bill.frequentPayment === true">

            <!-- Field: Frequência -->
            <b-col
              cols="12"
              md="3"
            >
              <b-form-group
                label="Frequência"
                label-for="frequency-value"
              >
                <v-select
                  v-model="bill.frequency"
                  :reduce="option => option.value"
                  :searchable="false"
                  :clearable="false"
                  input-id="frequency-value"
                  label="label"
                  :options="paymentFrequencyOptions"
                />
              </b-form-group>
            </b-col>

            <!-- Field: Nº de Parcelas -->
            <b-col
              cols="12"
              md="3"
            >
              <b-form-group
                label="Nº de Parcelas"
                label-for="numInstallments"
              >
                <b-input-group>
                  <b-form-input
                    id="numInstallments"
                    v-model="bill.numInstallments"
                    type="number"
                    value="1"
                  />
                </b-input-group>
              </b-form-group>
            </b-col>

            <!-- Field: Descrição -->
            <b-col
              cols="12"
              md="12"
            >
              <b-form-group
                label="Descrição"
                label-for="bill-description"
              >
                <b-input-group>
                  <b-form-input
                    id="bill-description"
                    v-model="bill.description"
                    type="text"
                  />
                </b-input-group>
              </b-form-group>
            </b-col>
          </template>
        </b-row>

        <hr>

        <!-- Footer -->
        <b-row>
          <b-col
            cols="12"
            md="6"
          />

          <b-col
            cols="12"
            md="3"
          >
            <b-button
              variant="outline-secondary"
              block
              @click="hidden"
            >
              Cancelar
            </b-button>
          </b-col>

          <b-col
            cols="12"
            md="3"
          >
            <!-- Action Buttons -->
            <b-button
              variant="primary"
              class="mb-1 mb-sm-0 mr-0 mr-sm-1"
              block
              :disabled="invalid"
              @click="save"
            >
              Salvar
            </b-button>
          </b-col>
        </b-row>
      </b-form>
    </b-modal>
  </validation-observer>
</template>

<script>
import {
  ref, onUnmounted, toRefs, watch,
} from '@vue/composition-api'
import {
  BRow, BCol, BFormGroup, BFormInput, BForm, BInputGroup, BInputGroupPrepend,
  BButton, BFormInvalidFeedback, BInputGroupAppend,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import _ from 'lodash'
import providerStoreModule from '@/views/apps/providers/providerStoreModule'
import patientStoreModule from '@/views/apps/patients/patientStoreModule'
import store from '@/store'
import { required } from '@validations'
import { ValidationProvider, ValidationObserver, localize } from 'vee-validate'
import CurrencyInput from '@/views/components/currency/CurrencyInput.vue'

export default {
  components: {
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BForm,
    BInputGroup,
    BInputGroupPrepend,
    BInputGroupAppend,
    BButton,
    BFormInvalidFeedback,
    ValidationProvider,
    ValidationObserver,
    CurrencyInput,

    vSelect,
  },

  props: {
    show: {
      type: Boolean,
      default: false,
    },
    billProp: {
      type: Object,
      default: () => ({}),
    },
  },

  setup(props, { emit }) {
    localize('pt_BR') // vee-validate messages
    const USER_APP_STORE_MODULE_PROVIDER = 'store-provider'
    const USER_APP_STORE_MODULE_NAME = 'store-patient'

    // Register module
    if (!store.hasModule(USER_APP_STORE_MODULE_PROVIDER)) store.registerModule(USER_APP_STORE_MODULE_PROVIDER, providerStoreModule)
    if (!store.hasModule(USER_APP_STORE_MODULE_NAME)) store.registerModule(USER_APP_STORE_MODULE_NAME, patientStoreModule)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(USER_APP_STORE_MODULE_PROVIDER)) store.unregisterModule(USER_APP_STORE_MODULE_PROVIDER)
      if (store.hasModule(USER_APP_STORE_MODULE_NAME)) store.unregisterModule(USER_APP_STORE_MODULE_NAME)
    })

    const showLocal = ref(false)
    const { show, billProp } = toRefs(props)

    const blankData = {
      id: null,
      number: null,
      doc_type: 'INVOICE',
      expiration_date: new Date().toISOString().split('T')[0],
      receiving_date: new Date().toISOString().split('T')[0],
      numInstallments: 1,
      value_installment: 0,
      percent_fee: 0,
      modular_fee: 0,
      percent_interest: 0,
      modular_interest: 0,
      description: null,
      providerId: null,
      patient_id: null,
      frequentPayment: false,
      frequency: 'MONTHLY',
    }

    const bill = ref(JSON.parse(JSON.stringify(blankData)))
    const providerOptions = ref([])
    const patientOptions = ref([])

    watch(show, newValue => {
      showLocal.value = newValue
      if (newValue && billProp.value && billProp.value.id) {
        bill.value = JSON.parse(JSON.stringify(billProp.value))
        bill.value.frequentPayment = false
        providerOptions.value = []
        providerOptions.value.push({
          value: bill.value.providerId,
          label: `${bill.value.transactable.corporate_name} | ${bill.value.transactable.trading_name || ''}`,
          data: bill.value.transactable,
        })

        if (bill.value.patient) {
          patientOptions.value = []
          patientOptions.value.push({
            value: bill.value.patient.id,
            label: bill.value.patient.name,
            data: bill.value.patient,
          })
        }
      }
    })

    const frequentPaymentOptions = ref([
      { label: 'Sim', value: true },
      { label: 'Não', value: false },
    ])
    const billDocTypeOptions = ref([ // INVOICE BANK_SLIP RECEIPT TRANSFER | Nota Fiscal Boleto Recibo Transferência
      { label: 'Nota Fiscal', value: 'INVOICE' },
      { label: 'Boleto', value: 'BANK_SLIP' },
      { label: 'Recibo', value: 'RECEIPT' },
      { label: 'Transferência', value: 'TRANSFER' },
    ])
    const paymentFrequencyOptions = ref([
      { label: 'Semanal', value: 'WEEKLY' },
      { label: 'Quinzenal', value: 'BIWEEKLY' },
      { label: 'Mensal', value: 'MONTHLY' },
    ])

    const feesMode = ref('percent')
    const fineMode = ref('percent')

    const changeFeesMode = () => {
      if (feesMode.value === 'percent') {
        bill.value.modular_interest = 0
        feesMode.value = 'modular'
      } else if (feesMode.value === 'modular') {
        bill.value.percent_interest = 0
        feesMode.value = 'percent'
      }
    }

    const changeFineMode = () => {
      if (fineMode.value === 'percent') {
        bill.value.modular_fee = 0
        fineMode.value = 'modular'
      } else if (fineMode.value === 'modular') {
        bill.value.percent_fee = 0
        fineMode.value = 'percent'
      }
    }

    const fetchProviderOptions = async (val, loading) => {
      if (val.length) {
        loading(true)
        const resp = await store.dispatch('store-provider/search', {
          searchQuery: val,
        })

        providerOptions.value = resp.data.map(o => ({
          value: o.id,
          label: `${o.corporate_name} | ${o.trading_name || ''}`,
          data: o,
        }))

        loading(false)
      } else {
        loading(false)
      }
    }

    const fetchPatientOptions = async (val, loading) => {
      if (val.length) {
        loading(true)
        const resp = await store.dispatch('store-patient/search', val)

        patientOptions.value = resp.data.map(o => ({
          value: o.id,
          label: `${o.code} | ${o.name || ''}`,
          data: o,
        }))

        loading(false)
      } else {
        loading(false)
      }
    }
    const debouncedSearchProvider = _.debounce(fetchProviderOptions, 600)
    const debouncedSearchPatient = _.debounce(fetchPatientOptions, 600)

    const onOpen = async () => {
      const resp = await store.dispatch('store-provider/search', {
        searchQuery: '',
      })

      providerOptions.value = resp.data.map(o => ({
        value: o.id,
        label: `${o.corporate_name} | ${o.trading_name || ''}`,
        data: o,
      }))
    }

    const hidden = () => {
      bill.value = JSON.parse(JSON.stringify(blankData))
      emit('hidden')
    }

    const save = async () => {
      emit('save', bill.value)
      hidden()
    }

    return {
      providerOptions,
      patientOptions,
      frequentPaymentOptions,
      billDocTypeOptions,
      paymentFrequencyOptions,
      bill,
      showLocal,
      required,
      feesMode,
      fineMode,

      hidden,
      save,
      onOpen,
      changeFeesMode,
      changeFineMode,
      debouncedSearchPatient,
      debouncedSearchProvider,
    }
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
