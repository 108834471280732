<template>
  <div>
    <b-row>
      <b-col
        cols="12"
        md="4"
      >
        <card-pay
          icon="ClockIcon"
          :statistic="totalNotPaid"
          statistic-title="A Pagar"
          color="primary"
        />
      </b-col>

      <b-col
        cols="12"
        md="4"
      >
        <card-paid
          icon="CheckIcon"
          :statistic="totalPaid"
          statistic-title="Pago"
          color="success"
        />
      </b-col>

      <b-col
        cols="12"
        md="4"
      >
        <card-expired
          icon="ArrowDownRightIcon"
          :statistic="totalExpired"
          statistic-title="Vencidos"
          color="danger"
        />
      </b-col>

    </b-row>

    <!-- Table Container Card -->
    <b-card
      no-body
      class="mb-0"
    >

      <div class="m-2">

        <!-- Table Top -->
        <b-row>

          <b-col
            cols="12"
            md="2"
          >
            <!-- Total Sum -->
            <span>Total</span>
            <h2
              class="mt-25 font-weight-bolder"
            >
              <sup class="font-medium-1">R$</sup>
              <span class="font-large-1 font-weight-bolder">
                {{ sumValue.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' }).replace('R$', '') }}
              </span>
            </h2>
          </b-col>

          <!-- Date Filter -->
          <b-col
            cols="12"
            md="3"
          >
            <b-form-group
              label="De"
              label-for="filter-start-date"
            >
              <b-input-group>
                <b-form-input
                  id="filter-start-date"
                  v-model="filterStartDate"
                  type="date"
                  trim
                />
              </b-input-group>
            </b-form-group>
          </b-col>
          <b-col
            cols="12"
            md="3"
          >
            <b-form-group
              label="Até"
              label-for="filter-end-date"
            >
              <b-input-group>
                <b-form-input
                  id="filter-end-date"
                  v-model="filterEndDate"
                  type="date"
                  trim
                />
              </b-input-group>
            </b-form-group>
          </b-col>

          <!-- Buttons -->
          <b-col
            cols="12"
            md="2"
          >
            <b-button
              variant="primary"
              block
              class="mt-2"
              @click="showPayModal"
            >
              <span>Pagar</span>
            </b-button>
          </b-col>
          <b-col
            cols="12"
            md="2"
          >
            <b-button
              variant="danger"
              block
              class="mt-2"
              @click="handleBill()"
            >
              <span>Lançamento</span>
            </b-button>
          </b-col>

          <!-- Per Page -->
          <b-col
            cols="12"
            md="3"
            class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
          >
            <label>Exibir</label>
            <v-select
              v-model="perPage"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="perPageOptions"
              :clearable="false"
              class="per-page-selector d-inline-block mx-50"
            />
          </b-col>

          <!-- Search -->
          <b-col
            cols="12"
            md="7"
          >
            <div class="d-flex align-items-center justify-content-end">
              <b-form-input
                v-model="searchQuery"
                class="d-inline-block"
                placeholder="Buscar..."
              />
            </div>
          </b-col>

          <!-- Button New Provider -->
          <b-col
            cols="12"
            md="2"
          >
            <b-button
              variant="outline-primary"
              block
              @click="newProviderShowModal = true"
            >
              <span>Novo Fornecedor</span>
            </b-button>
          </b-col>
        </b-row>
      </div>

      <!-- Table -->

      <b-overlay
        :show="showOverlay"
        rounded="sm"
        no-fade
      >
        <b-table
          ref="refListTable"
          class="position-relative tableMinHeght"
          :items="fetch"
          :tbody-tr-class="rowClass"
          small
          responsive
          :fields="tableColumns"
          primary-key="id"
          :sort-by.sync="sortBy"
          show-empty
          empty-text="Nenhum resultado encontrado"
          :sort-desc.sync="isSortDirDesc"
          select-mode="multi"
          no-sort-reset
        >
          <!-- Colunm: # -->
          <template #cell(#)="data">
            <b-form-checkbox
              v-if="data.item.situation !== 'PAID'"
              v-model="bills"
              :value="data.item"
              name="selected"
              unchecked-value="not_checked"
              @change="updValue"
            />
          </template>

          <!-- Colunm: Situação -->
          <template #cell(situation)="data">
            {{ handleSituation(data.item.situation) }}
          </template>

          <!-- Colunm: Vencimento -->
          <template #cell(dueDate)="data">
            {{ data.item.dueDate | dateTime('date') }}
          </template>

          <!-- Colunm: Acréscimos -->
          <template #cell(delay)="data">
            {{ data.item.delay && data.item.delay.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' }) }}
          </template>

          <!-- Colunm: Value -->
          <template #cell(value)="data">
            {{ data.item.value && data.item.value.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' }) }}
          </template>

          <!-- Column: Ações -->
          <template #cell(actions)="data">
            <b-dropdown
              variant="link"
              no-caret
              :right="$store.state.appConfig.isRTL"
            >

              <template #button-content>
                <feather-icon
                  icon="MoreVerticalIcon"
                  size="16"
                  class="align-middle text-body"
                />
              </template>

              <b-dropdown-item
                @click="handleDetails(data.item)"
              >
                <feather-icon icon="FileIcon" />
                <span class="align-middle ml-50">Detalhes</span>
              </b-dropdown-item>

              <b-dropdown-item
                v-if="!data.item.deleted_at"
                @click="handleBill(data.item)"
              >
                <feather-icon icon="EditIcon" />
                <span class="align-middle ml-50">Editar</span>
              </b-dropdown-item>

              <b-dropdown-item
                v-if="!data.item.deleted_at && data.item.situation !== 'PAID'"
                @click="handleDelete(data.item)"
              >
                <feather-icon icon="TrashIcon" />
                <span class="align-middle ml-50">Cancelar</span>
              </b-dropdown-item>
              <b-dropdown-item
                v-if="data.item.deleted_at"
                @click="handleReactive(data.item)"
              >
                <feather-icon icon="RotateCcwIcon" />
                <span
                  class="align-middle ml-50"
                >Reativar</span>
              </b-dropdown-item>
            </b-dropdown>
          </template>

        </b-table>
      </b-overlay>
      <div class="mx-2 mb-2">
        <b-row>

          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-start"
          >
            <span class="text-muted">Exibindo {{ dataMeta.from }} a {{ dataMeta.to }} de {{ dataMeta.of }} entradas</span>
          </b-col>
          <!-- Pagination -->
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-end"
          >

            <b-pagination
              v-model="currentPage"
              :total-rows="totalData"
              :per-page="perPage"
              first-number
              last-number
              class="mb-0 mt-1 mt-sm-0"
              prev-class="prev-item"
              next-class="next-item"
            >
              <template #prev-text>
                <feather-icon
                  icon="ChevronLeftIcon"
                  size="18"
                />
              </template>
              <template #next-text>
                <feather-icon
                  icon="ChevronRightIcon"
                  size="18"
                />
              </template>
            </b-pagination>

          </b-col>

        </b-row>
      </div>
    </b-card>

    <!-- Modal Pagar -->
    <modal-pay
      :total-value="sumValue"
      :bills="bills"
      :show="payShowModal"
      :accounts="accountsToPay"
      @save="saveDebitPayment"
      @hidden="payShowModal = false; bills = []"
    />

    <!-- Modal Lançamento -->
    <modal-handle-bill
      :show="handleBillShowModal"
      :bill-prop="bill"
      @save="saveBill"
      @hidden="handleBillShowModal = false; bill = ({})"
    />

    <!-- Modal: Novo Fornecedor -->
    <modal-new-provider
      :show="newProviderShowModal"
      @hidden="newProviderShowModal = false"
    />

    <!-- Modal Details -->
    <modal-details
      :show="detailsShowModal"
      :bill="bill"
      @hidden="detailsShowModal = false"
    />

  </div>
</template>

<script>
import {
  BCard, BRow, BCol, BTable, BFormCheckbox,
  BDropdown, BDropdownItem, BPagination, BFormInput, BButton,
  BFormGroup, BInputGroup, BOverlay,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import { ref, onUnmounted, computed } from '@vue/composition-api'
import store from '@/store'
import financeStoreModule from '@/views/apps/finances/financeStoreModule'
import useStoreAccount from '@/views/apps/finances/general-cashier/tabs/position/positionStoreModule'
import modalNewProvider from '@/views/apps/providers/modalNewProvider.vue'
// eslint-disable-next-line import/no-cycle
import useBillsList from './useBillsList'
import modalHandleBill from './modals/modalHandleBill.vue'
import modalPay from './modals/modalPay.vue'
import modalDetails from './modals/modalDetails.vue'
import CardPay from './cardPay.vue'
import CardPaid from './cardPaid.vue'
import CardExpired from './cardExpired.vue'

export default {
  components: {
    BCard,
    BRow,
    BCol,
    BTable,
    BFormCheckbox,
    BDropdown,
    BDropdownItem,
    BPagination,
    BFormInput,
    BButton,
    BFormGroup,
    BInputGroup,
    BOverlay,

    vSelect,
    modalHandleBill,
    modalPay,
    modalNewProvider,
    modalDetails,
    CardPay,
    CardPaid,
    CardExpired,
  },

  setup(props, { root }) {
    const USER_APP_STORE_MODULE_NAME = 'store-finance'
    const USER_APP_STORE_MODULE_ACCOUNT = 'store-account'

    if (!store.hasModule(USER_APP_STORE_MODULE_NAME)) store.registerModule(USER_APP_STORE_MODULE_NAME, financeStoreModule)
    if (!store.hasModule(USER_APP_STORE_MODULE_ACCOUNT)) store.registerModule(USER_APP_STORE_MODULE_ACCOUNT, useStoreAccount)

    onUnmounted(() => {
      if (store.hasModule(USER_APP_STORE_MODULE_NAME)) store.unregisterModule(USER_APP_STORE_MODULE_NAME)
      if (store.hasModule(USER_APP_STORE_MODULE_ACCOUNT)) store.unregisterModule(USER_APP_STORE_MODULE_ACCOUNT)
    })

    const bills = ref([])
    const sumArray = ref([])
    const sumValue = ref(0)
    const newProviderShowModal = ref(false)
    const accounts = ref([])
    const accountsToPay = computed(() => accounts.value.filter(el => el.shows_in.some(e => e === 'TO_PAY')))

    store.dispatch('store-account/fetch')
      .then(response => {
        accounts.value = response.data
      })
      .catch()

    const {
      tableColumns,
      perPage,
      currentPage,
      totalData,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refListTable,
      handleBillShowModal,
      payShowModal,
      filterStartDate,
      filterEndDate,
      totalExpired,
      totalNotPaid,
      totalPaid,
      bill,
      detailsShowModal,
      showOverlay,

      refetchData,
      fetch,
      saveBill,
      saveDebitPayment,
      handleSituation,
      handleBill,
      handleDetails,
      handleDelete,
      handleReactive,
    } = useBillsList(root)

    const layoutSkin = computed(() => store.state.appConfig.layout.skin)

    const rowClass = (item, type) => {
      if (item && (type === 'row') && item.deleted_at) return 'strikeout'
      if (item && (type === 'row') && item.situation === 'EXPIRED') return layoutSkin.value === 'light' ? 'expired' : 'expired-dark'
      return null
    }

    const updValue = () => {
      sumValue.value = 0
      if (!bills.value.length) return
      sumArray.value = bills.value.map(el => el.value_installment)
      sumValue.value = sumArray.value.reduce((a, b) => a + b, 0)
    }

    const showPayModal = () => {
      if (!bills.value.length) {
        root.$swal({
          title: 'Atenção!',
          text: 'Selecione ao menos uma transação.',
          icon: 'warning',
          customClass: {
            confirmButton: 'btn btn-primary',
          },
          buttonsStyling: false,
        })
        return
      }
      payShowModal.value = true
    }

    return {
      bills,
      sumArray,
      sumValue,
      totalExpired,
      totalNotPaid,
      totalPaid,
      tableColumns,
      perPage,
      currentPage,
      totalData,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refListTable,
      handleBillShowModal,
      payShowModal,
      newProviderShowModal,
      detailsShowModal,
      accountsToPay,
      filterStartDate,
      filterEndDate,
      bill,
      showOverlay,

      fetch,
      refetchData,
      saveBill,
      saveDebitPayment,
      handleSituation,
      rowClass,
      updValue,
      showPayModal,
      handleBill,
      handleDelete,
      handleDetails,
      handleReactive,
    }
  },
}
</script>

<style lang="scss">
  @import '@core/scss/vue/libs/vue-select.scss';
  @import '@core/scss/vue/libs/vue-sweetalert.scss';
</style>
