<template>
  <div>
    <b-modal
      id="modal-confirm"
      v-model="confirmValue"
      centered
      title="Confirmação"
      ok-title="Confirmar"
      cancel-title="Voltar"
      no-close-on-backdrop
      no-close-on-esc
      @ok="sendConfirmation"
      @cancel="cancelConfirmation"
      @close="cancelConfirmation"
    >
      <b-row>
        <b-col
          cols="12"
          md="10"
        >
          <span>Tem certeza que deseja confirmar este pagamento?</span>
        </b-col>

      </b-row>
    </b-modal>
  </div>
</template>

<script>
import {
  BModal, BRow, BCol,
} from 'bootstrap-vue'

export default {
  components: {
    BModal,
    BRow,
    BCol,
  },
  props: {
    confirm: {
      type: Boolean,
      default: null,
    },
  },
  data() {
    return {
      confirmValue: null,
    }
  },
  watch: {
    confirm(confirm) {
      if (confirm === true) {
        this.confirmValue = this.confirm
      }
    },
  },
  methods: {
    sendConfirmation() {
      this.$emit('confirmedEmit')
    },
    cancelConfirmation() {
      this.$emit('canceledEmit')
    },
  },
}
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>
