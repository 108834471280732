<template>
  <b-card no-body>
    <b-card-body class="d-flex justify-content-between align-items-center">
      <div class="truncate">
        <h2 class="mb-25 font-weight-bolder">
          <sup class="font-medium-1">R$</sup>
          <span
            class="font-large-1 font-weight-bolder"
          >
            {{ setEye === 'EyeIcon' ? statistic.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' }).replace('R$', '') : '----------' }}
          </span>
        </h2>
        <span
          class="cursor-pointer"
          @click="changeFilter"
        >
          <feather-icon
            size="16"
            :icon="setEye"
            class="mr-25 pb-25"
          /> {{ statisticTitle }}</span>
      </div>
      <b-avatar
        :variant="`light-${color}`"
        size="45"
      >
        <feather-icon
          size="21"
          :icon="icon"
        />
      </b-avatar>
    </b-card-body>
  </b-card>
</template>

<script>
import { BCard, BCardBody, BAvatar } from 'bootstrap-vue'

export default {
  components: {
    BCard,
    BCardBody,
    BAvatar,
  },
  props: {
    icon: {
      type: String,
      required: true,
    },
    statistic: {
      type: [Number, String],
      required: true,
    },
    statisticTitle: {
      type: String,
      default: '',
    },
    color: {
      type: String,
      default: 'primary',
    },
  },
  data() {
    return {
      setEye: 'EyeIcon',
    }
  },
  methods: {
    changeFilter() {
      if (this.setEye === 'EyeIcon') {
        this.setEye = 'EyeOffIcon'
      } else {
        this.setEye = 'EyeIcon'
      }
    },
  },
}
</script>
