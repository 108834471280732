/* eslint-disable func-names */
/* eslint-disable no-extend-native */
import { ref, watch, computed } from '@vue/composition-api'

import { currency, dateTime } from '@core/utils/filter'

import store from '@/store'

export default function useBillsList(root) {
  // AJUSTE DE VENCIMENTOS 30 DIAS EM MESES COM MENOS DE 31 DIAS
  Date.isLeapYear = year => (((year % 4 === 0) && (year % 100 !== 0)) || (year % 400 === 0))
  Date.getDaysInMonth = (year, month) => [31, (Date.isLeapYear(year) ? 29 : 28), 31, 30, 31, 30, 31, 31, 30, 31, 30, 31][month]
  Date.prototype.isLeapYear = function () { return Date.isLeapYear(this.getFullYear()) }
  Date.prototype.getDaysInMonth = function () { return Date.getDaysInMonth(this.getFullYear(), this.getMonth()) }
  Date.prototype.addMonths = function (value) {
    const n = this.getDate()
    this.setDate(1)
    this.setMonth(this.getMonth() + value)
    this.setDate(Math.min(n, this.getDaysInMonth()))
    // return `${this.getFullYear()}-0${this.getMonth() + 1}-${this.getDate()}`
    return this
  }

  const refListTable = ref(null)

  const tableColumns = [
    { key: '#' },
    {
      key: 'transactable.trading_name',
      label: 'fornecedor',
      tdClass: 'providerTdClass',
      sortable: true,
    },
    {
      key: 'situation',
      label: 'Situação',
      tdClass: 'situationTdClass',
      sortable: true,
    },
    {
      key: 'description',
      label: 'descrição',
      tdClass: 'descriptionTdClass',
      sortable: true,
    },
    {
      key: 'expiration_date',
      label: 'vencimento',
      formatter: value => dateTime(value),
      tdClass: 'expirationDateTdClass',
      sortable: true,
    },
    {
      key: 'delay',
      label: 'acréscimo ???',
      tdClass: 'delayTdClass',
      sortable: true,
    },
    {
      key: 'value_installment',
      label: 'valor',
      formatter: currency,
      tdClass: 'valueInstallmentTdClass',
      sortable: true,
    },
    {
      key: 'actions',
      label: 'ações',
      tdClass: 'actionsTdClass',
      sortable: false,
    },
  ]

  const perPage = ref(10)
  const totalData = ref(0)
  const totalExpired = ref(0)
  const totalNotPaid = ref(0)
  const totalPaid = ref(0)
  const currentPage = ref(1)
  const perPageOptions = [10, 25, 50]
  const searchQuery = ref('')
  const sortBy = ref('id')
  const isSortDirDesc = ref(false)
  const handleBillShowModal = ref(false)
  const detailsShowModal = ref(false)
  const bill = ref({})
  const payShowModal = ref(false)
  const date = new Date()
  const firstDay = new Date(date.getFullYear(), date.getMonth(), 1)
  const lastDay = new Date(date.getFullYear(), date.getMonth() + 1, 0)

  const formatDate = dte => `${dte.getFullYear()}-${(dte.getMonth() + 1) < 10 ? `0${dte.getMonth() + 1}` : (dte.getMonth() + 1)}-${dte.getDate() < 10 ? `0${dte.getDate()}` : dte.getDate()}`

  const filterStartDate = ref(formatDate(firstDay))
  const filterEndDate = ref(formatDate(lastDay))

  const dataMeta = computed(() => {
    const localItemsCount = refListTable.value ? refListTable.value.localItems.length : 0
    return {
      from: perPage.value * (currentPage.value - 1) + (localItemsCount ? 1 : 0),
      to: perPage.value * (currentPage.value - 1) + localItemsCount,
      of: totalData.value,
    }
  })

  const refetchData = () => {
    refListTable.value.refresh()
  }

  watch([currentPage, perPage, searchQuery, filterStartDate, filterEndDate], () => {
    refetchData()
  })

  const showToast = (icon = 'success', title = 'Sucesso', text = 'Dados Salvos com Sucesso.', confirmButton = 'btn btn-success') => {
    root.$swal({
      icon,
      title,
      text,
      customClass: {
        confirmButton,
      },
    })
  }

  const catchErr = e => {
    if (e.response && (e.response.status === 412 || e.response.status === 422)) showToast('warning', 'Atenção', e.response.data.message, 'btn btn-warning')
    else if (e.response.status === 401) showToast('error', 'Atenção', 'Sua sessão expirou, faça novo login!', 'btn btn-danger')
    else showToast('error', 'Atenção', 'Desculpe... Algo não saiu como esperado.', 'btn btn-danger')
  }

  const fetch = (ctx, callback) => {
    store
      .dispatch('store-finance/fetchBills', {
        q: '',
        // situation: filter.value,
        type: 'DEBIT',
        perPage: perPage.value,
        page: currentPage.value,
        sortBy: sortBy.value,
        sort: isSortDirDesc.value ? 'desc' : 'asc',
        filterStartDate: filterStartDate.value,
        filterEndDate: filterEndDate.value,
        withTrashed: true,
      })
      .then(response => {
        const { data, total } = response.data.transactions

        callback(data)
        totalData.value = total
        totalExpired.value = response.data.totalExpired
        totalNotPaid.value = response.data.totalNotPaid
        totalPaid.value = response.data.totalPaid
      })
      .catch(e => {
        catchErr(e)
      })
  }

  const resolveInstallments = installmentOptions => {
    const installments = []
    let dueDate = new Date(`${installmentOptions.expiration_date}T00:00:00`)
    for (let step = 0; step < installmentOptions.numInstallments; step += 1) {
      installments.push({
        expiration_date: `${dueDate.getFullYear()}-${(`0${dueDate.getMonth() + 1}`).slice(-2)}-${(`0${dueDate.getDate()}`).slice(-2)}`,
        installment: step + 1,
        value_installment: installmentOptions.value_installment,
        value_total: installmentOptions.value_installment * installmentOptions.numInstallments,
        dental_plan_id: installmentOptions.dental_plan_id || null,
        account_plan_id: installmentOptions.account_plan_id || null,
        type: 'DEBIT',
        origin: 'MONTHLY_PAYMENT',
        doc_type: installmentOptions.doc_type,
        percent_fee: installmentOptions.percent_fee || 0,
        modular_fee: installmentOptions.modular_fee || 0,
        percent_interest: installmentOptions.percent_interest || 0,
        modular_interest: installmentOptions.modular_interest || 0,
        number: installmentOptions.number || null,
        receiving_date: installmentOptions.receiving_date,
        description: installmentOptions.description,
      })

      if (installmentOptions.frequency === 'MONTHLY') dueDate = new Date(`${installmentOptions.expiration_date}T00:00:00`).addMonths(step + 1)
      if (installmentOptions.frequency === 'BIWEEKLY') dueDate.setDate(dueDate.getDate() + 15)
      if (installmentOptions.frequency === 'WEEKLY') dueDate.setDate(dueDate.getDate() + 7)
    }
    return installments
  }

  const handleBill = data => {
    if (data && data.id) {
      // eslint-disable-next-line no-param-reassign
      data.providerId = data.transactable_id
      bill.value = data
    }
    handleBillShowModal.value = true
  }

  const saveBill = async data => {
    try {
      if (!data.id) {
        await store.dispatch('store-finance/addDebitInstallments', {
          provider_id: data.providerId ? data.providerId : null,
          patient_id: data.patient_id ? data.patient_id : null,
          transactions: resolveInstallments(data),
        })
      } else {
        await store.dispatch('store-finance/editInstallment', {
          provider_id: data.providerId ? data.providerId : null,
          patient_id: data.patient_id ? data.patient_id : null,
          transaction: data,
        })
      }
      refetchData()
      showToast()
    } catch (e) {
      catchErr(e)
    }
  }

  const saveDebitPayment = async data => {
    try {
      await store.dispatch('store-finance/payDebitInstallments', data)
      refetchData()
      showToast()
    } catch (e) {
      catchErr(e)
    }
  }

  const handleDelete = data => {
    root.$swal({
      title: 'Deseja cancelar o registro?',
      text: 'Qual o motivo?',
      icon: 'warning',
      input: 'textarea',
      inputPlaceholder: 'Descreva o motivo aqui...',
      inputAttributes: {
        'aria-label': 'Descreva o motivo aqui',
      },
      // eslint-disable-next-line consistent-return
      inputValidator: value => {
        if (!value) {
          return 'Este campo é obrigatório!'
        }
      },
      showCancelButton: true,
      confirmButtonText: 'Sim',
      cancelButtonText: 'Não',
      customClass: {
        confirmButton: 'btn btn-primary',
        cancelButton: 'btn btn-secondary ml-1',
      },
      buttonsStyling: false,
    }).then(async result => {
      if (result.value) {
        try {
          await store.dispatch('store-finance/delete', { transaction: data, reason: result.value })
          refetchData()
          showToast('success', 'Sucesso', 'Cancelado com Sucesso.')
        } catch (e) {
          catchErr(e)
        }
      }
    })
  }

  const reactive = async data => {
    try {
      await store.dispatch('store-finance/reactive', data)
      refetchData()
    } catch (e) {
      catchErr(e)
    }
  }

  const handleReactive = data => {
    root.$swal({
      title: 'Tem certeza?',
      text: 'Deseja reativar o registro?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Sim',
      cancelButtonText: 'Não',
      customClass: {
        confirmButton: 'btn btn-primary',
        cancelButton: 'btn btn-secondary ml-1',
      },
      buttonsStyling: false,
    }).then(async result => {
      if (result.value) {
        await reactive(data)
        root.$swal({
          icon: 'success',
          title: 'Sucesso!',
          text: 'O registro foi reativado.',
          customClass: {
            confirmButton: 'btn btn-success',
          },
        })
      }
    })
  }

  const handleSituation = situation => {
    switch (situation) {
      case 'PAID':
        return 'Pago'
      case 'NOT_PAID':
        return 'Não Pago'
      case 'EXPIRED':
        return 'Vencido'
      default:
        return situation
    }
  }

  const handleDetails = data => {
    bill.value = data
    detailsShowModal.value = true
  }

  return {
    tableColumns,
    perPage,
    currentPage,
    totalData,
    totalExpired,
    totalNotPaid,
    totalPaid,
    dataMeta,
    perPageOptions,
    searchQuery,
    sortBy,
    isSortDirDesc,
    refListTable,
    handleBillShowModal,
    payShowModal,
    filterStartDate,
    filterEndDate,
    bill,
    detailsShowModal,

    fetch,
    refetchData,
    saveBill,
    saveDebitPayment,
    handleSituation,
    handleBill,
    handleDetails,
    handleDelete,
    handleReactive,
  }
}
